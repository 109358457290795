import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CR from "../../../../constants/RouteConstants";

// assets import
import MyAccount from "../../../../assets/icones/menu/friends.png";
import Config from "../../../../assets/icones/menu/config.png";
import Logout from "../../../../assets/icones/menu/logout.png";

import { map } from "lodash";
import SrcImages from "../../SrcImages/SrcImages";

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 25px 1fr;
  grid-auto-rows: 40px;
  grid-row-gap: 10px;
  justify-content: start;
  align-items: center;
`;
const FooterLinks = styled(Link)`
  text-decoration: none;
`;
const LinkImgWrapper = styled.div`
  display: flex;
  justify-content: end;
  grid-column-start: 2;
`;
const LinkItem = styled.p`
  color: ${(props) => props.theme.colors.white};
  text-transform: capitalize;
  font-weight: bold;
  grid-column-start: 3;
  margin-left: 10px;
`;
const FlagLinkWrapper = styled.div`
  display: grid;
  grid-auto-columns: 26px;
  grid-auto-rows: 26px;
  grid-column-gap: 10px;
  margin-left: 26px;
  align-items: center;
  overflow-x: auto;
  max-width: 148px;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #4d4f5c;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(77, 79, 92, 0.7);
  }
  /* scrollbar styling standard version */
  & {
    scrollbar-color: #4d4f5c transparent;
  }
`;
const FlagLink = styled.div`
  grid-row-start: 2;
  grid-column-start: ${(props) => props.position};
  & > img {
    box-sizing: content-box;
    border: ${(props) =>
      props.selectedLanguage
        ? `1px solid ${props.theme.colors.primary}`
        : "none"};
    border-radius: 3px;
  }
`;

/**
 *
 * @param {func} closeMenuHandler
 * @param {func} logout
 * @param {object} translations
 * @param {array} languagesList
 * @param {func} changeLanguageHandler
 * @returns {jsx}
 */
const Footer = ({
  closeMenuHandler,
  logout,
  translations,
  languagesList,
  changeLanguageHandler,
  selectedLanguage,
}) => {
  return (
    <Fragment>
      <FooterWrapper>
        <LinkImgWrapper>
          <img src={MyAccount} />
        </LinkImgWrapper>
        <FooterLinks onClick={closeMenuHandler} to={CR.myAccount}>
          <LinkItem>
            {translations["menu__myAccount"]}
            {/* mon compte */}
          </LinkItem>
        </FooterLinks>

        <LinkImgWrapper>
          <img src={Config} />
        </LinkImgWrapper>
        <FooterLinks onClick={closeMenuHandler} to={CR.userSetting}>
          <LinkItem>
            {translations["menu__mySettings"]}
            {/* Préférences */}
          </LinkItem>
        </FooterLinks>

        <LinkImgWrapper>
          <img src={Logout} />
        </LinkImgWrapper>
        <FooterLinks onClick={closeMenuHandler} to={CR.logOut}>
          <LinkItem onClick={logout}>
            {translations["menu__logOut"]}
            {/* Déconnecter */}
          </LinkItem>
        </FooterLinks>
      </FooterWrapper>

      <FlagLinkWrapper>
        {map(languagesList, (elem, id) => {
          return (
            <FlagLink key={id} selectedLanguage={selectedLanguage === elem.id}>
              <SrcImages
                src={elem.picture_path}
                width="26"
                height="17"
                idElem={elem.id}
                scope="private"
                click={changeLanguageHandler}
              />
            </FlagLink>
          );
        })}
      </FlagLinkWrapper>
    </Fragment>
  );
};

Footer.propTypes = {
  closeMenuHandler: PropTypes.func,
  logout: PropTypes.func,
  translations: PropTypes.object,
  languagesList: PropTypes.array,
  changeLanguageHandler: PropTypes.func,
};

export default Footer;
