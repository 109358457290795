import { find, forEach, isEmpty, isNull, isUndefined, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
// redux import
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { usagePicsConfig } from '../../constants/AssetsPicturesConfigList'
import ActionButton from '../../components/Ui/ActionButton';
// components
import Button from '../../components/Ui/Buttons';
// constant
import CR from '../../constants/RouteConstants';
// import helper
import imageImport from '../../helpers/imagesImport';
import ModalHOC from '../../Hoc/ModalHOC';
import { itemsKioskActionCreators } from '../../state/actions';
// selector
import { selectIdsUser } from '../../state/selectors/user';
import theme from "../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const ItemKioskWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 94px);
    grid-column-gap: 16px;
    grid-row-gap: 45px;
    grid-auto-rows: auto;
    margin: 0 80px 0 64px;
`;
const HeaderWrapper = styled.div`
    grid-column: 1 / -1;
    padding-top: 20px;
`;
const BackButton = styled.div`
    display: flex;
    height: 35px;
    font-weight: bold !important;
`;
const ItemCard = styled.div`
    display: flex;
    grid-column: 1 / -1;
    flex-direction: column;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.borderTable};
    margin: 22px 0;
    padding: 10px;
`;
// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
const AccessoriesCardWrapper = styled.div`
    display: flex;    
    flex-direction: column;
    margin-top: 24px;
    padding: 16px;
`;
const AccessoriesListWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 178px);
    grid-column-gap: ${props => props.gap ? '15px' : '76px'};
    grid-row-gap: 25px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
`;
const CheckBoxWraper = styled(Link)`
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
`;
const AccessoryImage = styled.img`
    display: flex;
    max-width: 178px;
    max-height: 178px;
    box-sizing: border-box;
`;
const AccessoriesTextWrapper = styled.div`
    display: flex;
    display: table-column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
`;
const AccessoriesText = styled.p`
    margin: ${props => props.margin ? '15px' : '0'} 0 0;
    text-align: center;
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.bold ? 'bold' : 'none'}
`;
// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
const SimilaresCardWrapper = styled.div`
    display: flex;    
    flex-direction: column;
    margin-top: 63px;
    padding: 16px;
`;
const SimilaresListWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 189px);
    grid-column-gap: ${props => props.gap ? '15px' : '76px'};
    grid-row-gap: 25px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
`;
const SimilaresBoxWraper = styled(Link)`
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
`;
const SimilaresImage = styled.img`
    display: flex;
    max-height: 250px;
    max-width: 189px;
    box-sizing: border-box;
`;
const SimilaresTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
`;
const SimilaresText = styled.p`
    margin: ${props => props.margin ? '15px' : '0'} 0 0;
    text-align: center;
    color: ${props => props.color ? props.color : props.theme.colors.primary};
    font-weight: ${props => props.bold ? 'bold' : 'none'}
`;
// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
const ResumeCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 84px repeat(6, 94px) 84px;
    grid-column-gap: 16px;
`;
const ResumeCardImg = styled.div`
    grid-column: 1 / 4;
    height: 400px;
    justify-content: center;
    align-items: center;
`;
const ResumeCardText = styled.div`
    grid-column: 4 / 9;
`;
const ImageBottle = styled.img`
    max-height: 400px;
    max-width: 302px;
`;
const LabelAndStatus = styled.div`
    position: relative;
    display: flex;
    height: 62px;
`;
const LabelAndStatusText = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 62px;
    font-weight: 900;
    margin-right: 5px;
`;
const TagWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 37px;
    width: 120px;
    height: 62px;
`;
const StockTag = styled.div`
    background-color: ${props => props.stockAlerte};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 119px;
    height: 25px;
    color: ${props => props.theme.colors.white};
`;
const PromoTag = styled.div`
    background-color: ${props => props.theme.colors.promoTag};
    visibility: ${props => props.visibility};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 119px;
    color: ${props => props.theme.colors.white};
`;
const StdPromPriceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    font-weight: 900;
    text-decoration: ${props => props.promo ? 'none' : 'line-through'};
    background-color: ${props => props.promo ? props.theme.colors.primary : 'none'};
    color: ${props => props.promo ? props.theme.colors.white : props.theme.colors.cancel};
    padding: 4px 8px;
    margin-top: 7px;
    margin-right: ${props => props.promo ? '12px' : '0'};
    margin-bottom: 13px;
`;
// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
const InfoCardWrapper = styled.div`
    display: flex;    
    flex-direction: column;
    margin-top: 75px;
    padding: 0 22px;
`;
const InfoCardHeaderWrapper = styled.div`
    position: relative;
    display: grid;
    justify-content: flex-start;
    align-items: stretch;
    grid-template-columns: repeat(4, 186px); 
    grid-template-rows: 45px;
    grid-column-gap: 5px;
    border-bottom: 1px solid ${props => props.theme.colors.borderTable};
    box-sizing: border-box;
`;
const BorderBottom = styled.div`
    position: absolute;
    display: flex;
    height: 1px;
    bottom: 1px;
    border-top: 1px solid ${props => props.theme.colors.borderTable};
    grid-column: 1 / -1;
`;
const InfoCardHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.selected ? props.theme.colors.white : props.theme.colors.borderTable};
    font-weight: bold;
    border: 1px solid ${props => props.theme.colors.borderTable};
    border-bottom: ${props => props.selected ? props.theme.colors.white : `1px solid ${props.theme.colors.borderTable}`};
    cursor: pointer;
    margin-bottom: -1px;
    z-index: ${props => props.selected ? '1' : '0'};
`;
const InfoContentCard = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.colors.borderTable};
    max-height: 300px;
    overflow-y: auto;
    border-top: none;
    justify-content: center;
    padding: 20px 50px;
`;
const UsageImage = styled.img`
    width: 50px;
    height: 50px;
    margin-right: 36px;
`;
const UsageWrapper = styled.div`
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-auto-rows: auto;
    grid-row-gap: 5px;
    align-items: center;
`;
// \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
const OneLinesTextWrapper = styled.div`
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 19px;     /* fallback */
   height: 19px;      /* fallback */
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
`;
const TextContentTitle = styled.p`
    color: ${props => props.color ? props.color : 'inherit'};
    margin: 2px 0;
    font-weight: 900;
`;
const LineWrapper = styled.div`
    display: flex;
    margin-top: ${props => `${props.margin}px`};
`;
const BlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: ${props => `${props.margin}px`};
`;
const TextFormat = styled.p`
    margin: 0;
    padding: 0;
    color: ${props => props.color};
    font-weight: ${props => props.weight};
`;
const FeaturesWrapper = styled.div`
    grid-column: 4 / 9;
    border: 1px solid ${props => props.theme.colors.borderTable};
    padding: 15px;
    display: grid;
    grid-template-columns: 0.33fr 0.66fr;
    grid-auto-rows: 35px;
    margin-top: 15px;
    align-items: center;
`;
const SimilareImagesWrapper = styled.div`
    display: flex;
    justify-items: center;
    max-height: 250px;
    max-width: 189px;
`;
/////////////////////////////
//  END style
////////////////////////////
export class ProductDetail extends Component {
    static propTypes = {
        actions: PropTypes.object,
        itemsKiosk: PropTypes.object,
        userKiosk: PropTypes.object,
        modalHandler: PropTypes.func,
    };


    state = {
        item: {},
        infoCard: {
            description: {
                label: {},
                active: false,
            },
            technical: {
                label: {},
                active: false,
            },
            usage: {
                label: {},
                active: false,
            },
            advices: {
                label: {},
                active: false,
            },
        }
    };

    componentDidMount = () => {
        this.getItemKiosk()
    };

    getItemKiosk = () => {
        const { actions, match } = this.props;
        const itemKioskId = match.params;

        actions.getItemKiosk(itemKioskId.id)
    };

    getItemAndHydrate = () => {
        const { itemKiosk, translations } = this.props;
        this.setState({
            item: itemKiosk,
            infoCard: {
                description: {
                    id: 1,
                    title: translations['page__itemDetail__tab_fullDescription_title'],
                    label: itemKiosk.description,
                    active: true,
                },
                // technical: {
                //     id: 2,
                //     title: translations['page__itemDetail__tab_datasheet_title'],
                //     label: {},
                //     active: false,
                // },
                usage: {
                    id: 3,
                    title: translations['page__itemDetail__tab_usages_title'],
                    label: { ...itemKiosk.usages },
                    active: false,
                },
                // advices: {
                //     id: 4,
                //     title: translations['page__itemDetail__tab_helpAndadvices_title'],
                //     label: {},
                //     active: false,
                // },
            }
        })
    };

    componentDidUpdate = (prevProps) => {
        const { location, match, history, itemKiosk } = this.props;

        if (!location.pathname.includes('modal') &&
            match.params.id !== prevProps.match.params.id
        ) {
            this.getItemKiosk()
        }
        if (this.props.userKiosk !== prevProps.userKiosk) {
            history.push(CR.kioskProduct)
        }
        if (itemKiosk !== prevProps.itemKiosk) {
            this.getItemAndHydrate()
        }
    };

    infoCardSelectedHandler = (idElem) => {
        const oldState = { ...this.state };
        forEach(oldState.infoCard, (elem,) => {
            if (elem.id === idElem) {
                if (idElem === 1 || idElem === 3) {
                    elem.active = true
                } else {
                    oldState.infoCard.description.active = true
                }
            } else {
                elem.active = false
            }
        });
        this.setState({ ...oldState })
    };

    infoCardLayout = () => {
        const { infoCard } = this.state;
        const contentToDisplay = find(infoCard, { active: true }) || find(infoCard, { id: 1 });
        let componentToDisplay = 'Vide';

        if (contentToDisplay.id === 3) {
            componentToDisplay =
                <UsageWrapper>
                    {map(contentToDisplay.label, (item, id) => {
                        const pic = find(usagePicsConfig, { id: item.id })
                        return (
                            <Fragment
                                key={id}
                            >
                                {/* Seems that we don't need to use prefix in item.picture */}
                                {!isUndefined(pic) && <UsageImage
                                    src={`${pic.picture}`}
                                />}
                                <TextFormat
                                    color={theme.colors.primary}
                                    weight='900'
                                >
                                    {
                                        item.label
                                    }
                                </TextFormat>
                            </Fragment>
                        )
                    }
                    )}
                </UsageWrapper>

        } else if (contentToDisplay.id === 1) {
            componentToDisplay = contentToDisplay.label
        }
        return (
            <InfoCardWrapper>
                <InfoCardHeaderWrapper>
                    {
                        map(infoCard, (item, id) => (
                            <InfoCardHeader
                                key={id}
                                selected={item.active}
                                onClick={() => this.infoCardSelectedHandler(item.id)}
                            >
                                {item.title}
                            </InfoCardHeader>
                        ))
                    }
                    <BorderBottom />
                </InfoCardHeaderWrapper>
                <InfoContentCard>
                    {componentToDisplay}
                </InfoContentCard>
            </InfoCardWrapper>
        )
    };

    render() {
        const { item } = this.state;
        const { modalHandler, match, history, translations } = this.props;
        if (isEmpty(item)) {
            return <div></div>
        }
        const visibilityPromo = item.promoPrice ? 'visible' : 'hidden';

        let tagStockColor = '';
        let tagText = '';
        if (item.lockerStock <= item.stockAlert && item.lockerStock > 0) {
            tagStockColor = theme.colors.warning;
            tagText = translations['page__itemCatalogue__itemCard_inStock'] + ' ' + item.lockerStock + '/' + item.kioskCapacity
        } else if (item.lockerStock <= 0) {
            tagStockColor = theme.colors.error;
            tagText = translations['page__itemCatalogue__itemCard_noStock'] + ' ' + item.lockerStock + '/' + item.kioskCapacity
        } else if (item.lockerStock > item.stockAlert) {
            tagStockColor = theme.colors.validation;
            tagText = translations['page__itemCatalogue__itemCard_inStock'] + ' ' + item.lockerStock + '/' + item.kioskCapacity
        }
        return (
            <HeaderWrapper>
                <BackButton>
                    <Button
                        goBack
                        weight='bold'
                        text={translations['common__text__previousPage']}
                        actionHandler={history.goBack}
                    />
                </BackButton>
                <ItemKioskWrapper>
                    <ItemCard>
                        <ResumeCardWrapper>
                            <ResumeCardImg>
                                <ImageBottle
                                    src={imageImport(item.picture)}
                                />
                            </ResumeCardImg>
                            <ResumeCardText>
                                <LabelAndStatus>
                                    <LabelAndStatusText>
                                        <TextContentTitle
                                            color={theme.colors.primary}
                                        >
                                            {item.itemFamily.label}
                                        </TextContentTitle>
                                        <TextContentTitle>
                                            {item.itemBrand.label}
                                        </TextContentTitle>
                                        <OneLinesTextWrapper>
                                            <TextContentTitle>
                                                {item.label}
                                            </TextContentTitle>
                                        </OneLinesTextWrapper>
                                    </LabelAndStatusText>
                                    <TagWrapper>
                                        <StockTag
                                            stockAlerte={tagStockColor}
                                        >
                                            {tagText}
                                        </StockTag>
                                        <PromoTag
                                            visibility={visibilityPromo}
                                        >
                                            {translations['page__itemCatalogue__itemCard_promoTag']}
                                        </PromoTag>
                                    </TagWrapper>
                                    <ActionButton
                                        link={`${match.url}${CR.modal.kioskProduct}`}
                                        actionHandler={() => modalHandler(item)}
                                        size='62'
                                        dotSize={true}
                                    />
                                </LabelAndStatus>
                                <LineWrapper>
                                    {
                                        item.promoPrice ?
                                            <Fragment>
                                                <StdPromPriceWrapper promo>
                                                    {item.promoPrice.toFixed(2) + item.currency.symbol}
                                                </StdPromPriceWrapper>
                                                <StdPromPriceWrapper >
                                                    {item.standardPrice.toFixed(2) + item.currency.symbol}
                                                </StdPromPriceWrapper>
                                            </Fragment>
                                            :
                                            <StdPromPriceWrapper promo>
                                                {item.standardPrice.toFixed(2) + item.currency.symbol}
                                            </StdPromPriceWrapper>
                                    }
                                </LineWrapper>
                                <LineWrapper>
                                    {
                                        item.withDeposit &&
                                        <BlockWrapper
                                            margin='37'
                                        >
                                            <TextFormat
                                                color={theme.colors.primary}
                                            >
                                                {translations['common__text__deposit']}
                                            </TextFormat>
                                            <TextFormat
                                                color={theme.colors.primary}
                                                weight='900'
                                            >
                                                {`${item.depositPrice.toFixed(2)}${item.currency.symbol}`}
                                            </TextFormat>
                                        </BlockWrapper>
                                    }
                                    {
                                        item.mainAccessory &&
                                        <BlockWrapper>
                                            <TextFormat>
                                                {translations['page__itemDetail__defaultAccessory'] + " :"}
                                            </TextFormat>
                                            <TextFormat
                                                color={theme.colors.primary}
                                            >
                                                {`${item.mainAccessory.label}`}
                                            </TextFormat>
                                        </BlockWrapper>
                                    }
                                </LineWrapper>
                                <FeaturesWrapper>
                                    {
                                        item.itemFeatures.length ?

                                            item.itemFeatures.map((elem, id) => (
                                                <Fragment
                                                    key={id}
                                                >
                                                    <TextFormat
                                                        weight='bold'
                                                    >
                                                        {`${elem.label} : `}
                                                    </TextFormat>
                                                    <TextFormat>
                                                        {`${elem.value}`}
                                                    </TextFormat>
                                                </Fragment>
                                            ))
                                            :
                                            translations['common__text__noCaracteristics']
                                    }
                                </FeaturesWrapper>
                            </ResumeCardText>
                        </ResumeCardWrapper>
                        {
                            item.itemType !== "Accessoire" &&
                            <AccessoriesCardWrapper>
                                <LineWrapper>
                                    <TextFormat
                                        color={theme.colors.primary}
                                        margin='0'
                                        weight='bold'
                                    >
                                        {translations['page__itemDetail__accessories'] + " :"}
                                    </TextFormat>
                                </LineWrapper>
                                <AccessoriesListWrapper>
                                    {
                                        item.accessories.map((elem, id) => (

                                            <CheckBoxWraper
                                                key={id}
                                                to={`${CR.productDetail.text}${elem.id}`}
                                            >
                                                <AccessoryImage
                                                    src={imageImport(elem.picture)}
                                                />
                                                <AccessoriesTextWrapper
                                                    key={id}
                                                >
                                                    <AccessoriesText>
                                                        {elem.label}
                                                    </AccessoriesText>
                                                    <AccessoriesText
                                                        colored={theme.colors.validation}
                                                        bold
                                                        margin
                                                    >
                                                        {
                                                            elem.promoPrice ?
                                                                `${elem.promoPrice.toFixed(2)}${item.currency.symbol}`
                                                                :
                                                                `${elem.standardPrice.toFixed(2)}${item.currency.symbol}`
                                                        }
                                                    </AccessoriesText>
                                                </AccessoriesTextWrapper>
                                            </CheckBoxWraper>
                                        ))
                                    }
                                </AccessoriesListWrapper>

                            </AccessoriesCardWrapper>
                        }
                        {this.infoCardLayout()}

                        <SimilaresCardWrapper>
                            <LineWrapper>
                                <TextFormat
                                    color={theme.colors.primary}
                                    margin='0'
                                    weight='bold'
                                >
                                    {translations['page__itemDetail__similarItems'] + " :"}
                                </TextFormat>
                            </LineWrapper>
                            <SimilaresListWrapper gap>
                                {
                                    !isNull(item.itemsSimilar) ?
                                        item.itemsSimilar.map((elem, id) => (

                                            <SimilaresBoxWraper
                                                to={`${CR.productDetail.text}${elem.id}`}
                                                key={id}
                                            >
                                                <SimilareImagesWrapper>
                                                    <SimilaresImage
                                                        src={imageImport(elem.picture)}
                                                    />
                                                </SimilareImagesWrapper>

                                                <SimilaresTextWrapper
                                                    key={id}
                                                >
                                                    <SimilaresText
                                                        bold
                                                    >
                                                        {elem.itemBrand.label}
                                                    </SimilaresText>
                                                    <SimilaresText
                                                        color={theme.colors.menu.wrapper}
                                                        bold
                                                    >
                                                        {elem.label}
                                                    </SimilaresText>
                                                    <LineWrapper
                                                        colored={theme.colors.validation}
                                                        bold
                                                    >
                                                        {
                                                            elem.promoPrice ?
                                                                <Fragment>
                                                                    <StdPromPriceWrapper promo>
                                                                        {elem.promoPrice.toFixed(2) + item.currency.symbol}
                                                                    </StdPromPriceWrapper>
                                                                    <StdPromPriceWrapper >
                                                                        {elem.standardPrice.toFixed(2) + item.currency.symbol}
                                                                    </StdPromPriceWrapper>
                                                                </Fragment>
                                                                :
                                                                <StdPromPriceWrapper promo>
                                                                    {elem.standardPrice.toFixed(2) + item.currency.symbol}
                                                                </StdPromPriceWrapper>
                                                        }
                                                    </LineWrapper>
                                                </SimilaresTextWrapper>
                                            </SimilaresBoxWraper>
                                        )) : null
                                }
                            </SimilaresListWrapper>

                        </SimilaresCardWrapper>

                    </ItemCard>
                </ItemKioskWrapper>

            </HeaderWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        itemKiosk: state.itemsKiosk.selectedItem,
        userKiosk: selectIdsUser(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(itemsKioskActionCreators, dispatch)
    }
};

export default withRouter(ModalHOC(connect(mapStateToProps, mapDispatchToProps)(ProductDetail)))

