import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { isEmpty, map } from "lodash";

// redux import
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loginActionCreators,
  languagesActionCreators,
} from "../../state/actions";
import { selectLanguagesDefault } from "../../state/selectors/languages";

// style elements
import styled from "styled-components";

// assets import
import { ReactComponent as IconePortrait } from "../../assets/icones/login/loginPortrait.svg";
import { ReactComponent as IconeMdp } from "../../assets/icones/login/mdpCle.svg";
import { ReactComponent as Logo } from "../../assets/images/logoLogin.svg";
import { ReactComponent as LogoBackground } from "../../assets/images/logoBackground.svg";

// component
import SrcImages from "../../components/Ui/SrcImages/SrcImages";
import TranslationHandler from "../../Hoc/TranslationHandler/TranslationHandler";
import ModalBody from "../../components/Ui/Modals/ModalBody";
import Button from "../../components/Ui/Buttons";
import ModalSummerize from "../Modals/ModalSummerize";
import ForgotPassword from "../Modals/ForgotPassword/ForgotPassword";
import theme from "../../theme/theme";
//////////////////////////////
// START Component style
/////////////////////////////

// layout page login
const LoginLayout = styled.div`
  @import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900&display=swap");
  font-family: "Lato", sans-serif;
  font-size: 14px;
  position: relative;
  width: 1024px;
  margin: auto;
`;
// logo component
const ImgContent = styled.div`
  display: inherit;
  align-content: center;
  margin-bottom: 100px;
  margin-top: 186px;
`;
// svg bottom left component
const ImgBackground = styled.div`
  position: absolute;
  width: 314px;
  height: 424px;
  top: 343px;
  right: 702px;
  overflow: hidden;
`;

const Textmdp = styled.p`
  color: ${(props) => props.theme.colors.cancel};
  font-size: ${(props) => props.theme.font.fxs};
`;

const FlagWrapper = styled.div`
  width: 400px;
  margin-top: 90px;
  display: grid;
  -moz-box-pack: center;
  -moz-box-align: center;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(${props => props.col}, 30px);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
`;
const LoginWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}%` : null)};
`;
const InputLogin = styled.input`
  height: 45px;
  width: 195px;
  border-radius: 0 3px 3px 0;
  border: 1px solid ${(props) => props.theme.colors.cancel};
  padding-left: 5px;
  box-sizing: border-box;
  font-family: Lato;
  font-size: 14px;
  :focus {
    outline: none !important;
    border-color: ${(props) => props.theme.colors.primary};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.white} inset;
  }
`;
const IconeLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 3px 0 0 3px;
  background-color: ${(props) => props.theme.colors.primary};
`;
const ButtonElement = styled.button`
  border: none;
  width: 240px;
  height: 45px;
  color: white;
  border-radius: 3px;
  font-size: 18px;
  background-color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;
const ErrorInputMsg = styled.div`
  position: absolute;
  left: 250px;
  color: ${(props) => props.theme.colors.error};
`;
const ErrorFormMsg = styled.div`
  position: absolute;
  top: -40px;
  width: 240px;
  text-align: center;
  color: ${(props) => props.theme.colors.error};
`;
const ButtonWrapper = styled.div`
  display: flex;
  height: 30px;
`;
const TextModal = styled.div`
  margin: 0;
  padding: 15px 0;
`;
/////////////////////////////
// END component Style
////////////////////////////

class Login extends Component {
  static propTypes = {
    loginActions: PropTypes.object,
    languagesActions: PropTypes.object,
    user: PropTypes.object,
    languages: PropTypes.object,
    defaultLanguages: PropTypes.object,
  };

  state = {
    showForgotPassword: false,
    forgotPasswordSuccess: false,
  };

  componentDidUpdate() {
    this.checkUserConnected();
  }

  componentDidMount() {
    this.checkUserConnected();
  }

  checkUserConnected = () => {
    if (this.props.user.isConnected) {
      if (this.props.location.pathname === "/login") {
        this.props.history.push("/home");
      }
    }
  };

  /**
   * call back end with payload user auth to get JWT
   * @param   {Object}        values contains value of form field
   */
  onSubmitHandler = (values) => {
    const { loginActions } = this.props;
    const user = {
      login: values.identifiant,
      password: values.password,
      idLanguage: this.props.languages.selectedLanguage
    };
    loginActions.getLogin(user);
  };

  /**
   * check validation of field enter by user
   * @param   {Object}        values contains value of form field
   * @return  {Object}        errors object
   */
  validateFormField = (values) => {
    const { translations } = this.props;
    const errors = {};

    if (!values.identifiant) {
      errors.identifiant = translations["common__text__requiredField"];
    }
    if (!values.password) {
      errors.password = translations["common__text__requiredField"];
    }
    return errors;
  }; // end function

  forgotPasswordHandler = () => {
    this.setState({
      showForgotPassword: true,
    });
  };

  closeForgotPasswordHandler = () => {
    this.setState({
      showForgotPassword: false,
    });
  };

  forgotPasswordSubmitedHandler = () => {
    this.closeForgotPasswordHandler();
    this.setState({
      forgotPasswordSuccess: true,
    });
  };

  forgotPasswordCloseSubmitedHandler = () => {
    this.closeForgotPasswordHandler();
    this.setState({
      forgotPasswordSuccess: false,
    });
  };

  render() {
    const {
      languages,
      translations,
      user,
      changeLanguageHandler,
      selectedLanguage,
    } = this.props;
    if (isEmpty(languages.items)) {
      return <div></div>;
    }
    return (
      <Fragment>
        {/*MODAL FORGOT PASSWORD*/}
        <ModalSummerize
          showModal={this.state.showForgotPassword}
          closeModalHandler={() => this.closeForgotPasswordHandler()}
          title={translations["modal__forgotPassword__title"]}
        >
          <ModalBody>
            <TextModal>{translations["modal__forgotPassword__body"]}</TextModal>
            <ForgotPassword
              translations={translations}
              closeModalHandler={this.closeForgotPasswordHandler}
              forgotPasswordSubmitedHandler={this.forgotPasswordSubmitedHandler}
            />
          </ModalBody>
        </ModalSummerize>
        {/*MODAL FORGOT PASSWORD*/}
        {/*MODAL CONFIRM RESEND PASSWORD*/}

        {user.forgotPassword > 0 && (
          <ModalSummerize
            showModal={this.state.forgotPasswordSuccess}
            closeModalHandler={() => this.forgotPasswordCloseSubmitedHandler()}
            title={
              user.forgotPassword === 200
                ? translations["modal__forgotPassword__modalSuccess_title"]
                : "erreur inattendue"
            }
            level={user.forgotPassword === 200 ? "validation" : "error"}
          >
            <ModalBody>
              <TextModal>
                {user.forgotPassword === 200
                  ? translations["modal__forgotPassword__modalSuccess_body"]
                  : "Une erreur inattendue est survenue. Veuillez réessayer ultérieurement ou contacter votre support"}
              </TextModal>
              <ButtonWrapper>
                <Button
                  actionHandler={() =>
                    this.forgotPasswordCloseSubmitedHandler()
                  }
                  text={translations["common__text__save"]}
                  margin="true"
                  bgColor={theme.colors.validation}
                />
              </ButtonWrapper>
            </ModalBody>
          </ModalSummerize>
        )}
        {/*MODAL CONFIRM RESEND PASSWORD*/}

        <LoginLayout>
          <ImgBackground>
            <LogoBackground />
          </ImgBackground>

          <LoginWrapper>
            <ImgContent>
              <Logo />
            </ImgContent>

            <LoginWrapper>
              <Form
                onSubmit={this.onSubmitHandler}
                validate={this.validateFormField}
                render={({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    {this.props.user.error.data && (
                      <ErrorFormMsg>
                        {translations[this.props.user.error.data.traductionKey]}
                      </ErrorFormMsg>
                    )}
                    <Field name="identifiant">
                      {({ input, meta }) => (
                        <InputWrapper marginTop={5}>
                          <IconeLogin>
                            <IconePortrait />
                          </IconeLogin>
                          <InputLogin
                            {...input}
                            type="text"
                            placeholder={
                              translations["page__login__form_username"]
                            }
                            maxLength={"150"}
                          />
                          {(meta.error || meta.submitError) && meta.touched && (
                            <ErrorInputMsg>
                              {meta.error || meta.submitError}{" "}
                            </ErrorInputMsg>
                          )}
                        </InputWrapper>
                      )}
                    </Field>
                    <Field name="password">
                      {({ input, meta }) => (
                        <InputWrapper>
                          <IconeLogin>
                            <IconeMdp />
                          </IconeLogin>
                          <InputLogin
                            {...input}
                            type="password"
                            placeholder={
                              translations["page__login__form_password"]
                            }
                            maxLength={"50"}
                          />
                          {meta.error && meta.touched && (
                            <ErrorInputMsg>{meta.error}</ErrorInputMsg>
                          )}
                        </InputWrapper>
                      )}
                    </Field>

                    <ButtonElement type="submit" disabled={submitting}>
                      {translations["page__login__button_logIn"]}
                    </ButtonElement>
                  </form>
                )}
              />
            </LoginWrapper>

            <Textmdp onClick={this.forgotPasswordHandler}>
              {translations["page__login__forgotPassword"]}
            </Textmdp>
            <FlagWrapper col={languages.items.length}>
              {map(languages.items, (elem, id) => {
                return (
                  <SrcImages
                    key={id}
                    src={elem.picture_path}
                    width="26"
                    height="17"
                    idElem={elem.id}
                    scope="public"
                    click={changeLanguageHandler}
                    additionnalStyle={{
                      border:
                        languages.selectedLanguage === elem.id
                          ? `1px solid ${theme.colors.primary}`
                          : "none",
                      borderRadius: "3px",
                      boxSizing: "content-box",
                    }}
                  />
                );
              })}
            </FlagWrapper>
          </LoginWrapper>
        </LoginLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    languages: state.languages,
    defaultLanguages: selectLanguagesDefault(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginActions: bindActionCreators(loginActionCreators, dispatch),
  languagesActions: bindActionCreators(languagesActionCreators, dispatch),
});

export default TranslationHandler(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);